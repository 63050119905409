import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { createContext, useCallback, useContext, useState } from 'react';

const AlertContext = createContext(({ title, content, onOk, onCancel }) => {});

const ConfirmDialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [onCancel, setOnCancel] = useState();
  const [onOk, setOnOk] = useState();

  const handleCancel = useCallback(() => {
    setOpen(false);
    onCancel && onCancel();
  }, [onCancel]);

  const handleOk = useCallback(() => {
    setOpen(false);
    onOk && onOk();
  }, [onOk]);

  const openAlert = useCallback(({ title, content, onOk, onCancel }) => {
    setTitle(title);
    setContent(content);
    setOnOk(() => onOk);
    setOnCancel(() => onCancel);
    setOpen(true);
  }, []);

  return (
    <AlertContext.Provider value={openAlert}>
      {children}
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} variant="contained" color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </AlertContext.Provider>
  );
};

export const useConfirmDialog = () => {
  return useContext(AlertContext);
};

export default ConfirmDialogProvider;
