import GunbotStorage from 'assets/js/gunbot.storage';
import React, { useState, createContext, useCallback, useMemo } from 'react';

const isFamiliar = GunbotStorage.get('isKnowWellGunbot', false);
const isFamiliarUpTo = parseFloat(GunbotStorage.get('isKnownWellUpTo', '0'))

export const StepGuideContext = createContext({
  isKnowWell: false,
  setKnowWell: knowWell => {},
  isPending: false,
  currentStep: 1,
  setupStep: 10,
  totalStep: 15,
  manualStep: 5,
  setCurrentStep: step => {},
  setTotalStep: step => {},
  setPending: isPending => {},
  setSetupStep: step => {},
  setManualStep: step => {},
});

const StepGuideProvider = ({ children }) => {
  const [isKnowWell, setIsKnowWell] = useState(isFamiliar);
  const [isKnowWellUpTo, setIsKnowWellUpTo] = useState(isFamiliarUpTo);
  const [isPending, setPending] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [setupStep, setSetupStep] = useState(3);
  const [manualStep, setManualStep] = useState(setupStep + 2);
  const [totalStep, setTotalStep] = useState(5);

  const handleIsKnowWell = useCallback(() => {
    const knownUpTo = parseFloat(GunbotStorage.get('isKnownWellUpTo', '0'))

    if (knownUpTo < 1) {
      GunbotStorage.set('isKnownWellUpTo', '1')
      setIsKnowWellUpTo(1)
    }
    else if (knownUpTo < 2) {
      GunbotStorage.set('isKnownWellUpTo', '2')
      setIsKnowWellUpTo(2)
    }
    else if (knownUpTo < 3) {
      GunbotStorage.set('isKnownWellUpTo', '3')
      setIsKnowWellUpTo(3)
    }

    GunbotStorage.set('isKnowWellGunbot', true);
    setIsKnowWell(true);
  }, []);

  const value = useMemo(() => {
    return {
      isKnowWell,
      isKnowWellUpTo,
      currentStep,
      setupStep,
      isPending,
      totalStep,
      manualStep,
      setKnowWell: handleIsKnowWell,
      setCurrentStep,
      setSetupStep,
      setTotalStep,
      setPending,
      setManualStep,
    };
  }, [isKnowWell, isKnowWellUpTo, currentStep, setupStep, totalStep, isPending, manualStep]);

  return <StepGuideContext.Provider value={value}>{children}</StepGuideContext.Provider>;
};

export default StepGuideProvider;
