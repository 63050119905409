import React, { createContext, useCallback, useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';

import { ChainIdsArray } from 'consts/web3';
import useEagerConnect from 'hooks/web3/useEagerConnect';
import useInactiveListener from 'hooks/web3/useInactiveListener';
import GunbotStorage, { METAMASK_CONNECTED, WALLET_ADDRESS } from 'assets/js/gunbot.storage';
import { useMemo } from 'react';

export const Web3ManageContext = createContext({
  account: undefined,
  activate: () => {},
  deactivate: () => {},
});

export default function Web3ManageProvider({ children }) {
  const { activate, deactivate, account } = useWeb3React();
  const [connected, setConnected] = useState(GunbotStorage.get(METAMASK_CONNECTED, false));

  const checkedConnect = useEagerConnect(connected);
  useInactiveListener(checkedConnect);

  const handleActivate = useCallback(async () => {
    GunbotStorage.set(METAMASK_CONNECTED, true);
    setConnected(true);
    return activate(Injected);
  }, []);

  const handleDeactivate = useCallback(async () => {
    GunbotStorage.set(METAMASK_CONNECTED, false);
    setConnected(false);
    return deactivate();
  }, []);

  const value = useMemo(() => {
    GunbotStorage.set(WALLET_ADDRESS, account);
    return {
      account,
      activate: handleActivate,
      deactivate: handleDeactivate,
    };
  }, [activate, deactivate, account]);

  if (!checkedConnect) return null;

  return <Web3ManageContext.Provider value={value}>{children}</Web3ManageContext.Provider>;
}

export const Injected = new InjectedConnector({
  supportedChainIds: ChainIdsArray,
});
